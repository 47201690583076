import React from 'react';
import { Element } from 'react-scroll';
import './Howtobuy.css';


const Howtobuy = () => {
  return (
    <Element name='howToBuyPage' className="howtobuy-container">
      <div className="text-container6">
        <h2>HOW TO BUY</h2>
        <div className='descrip6'>
            <h3>1. Set Up a Compatible Wallet:</h3>
                <p>Ensure you have a wallet that is compatible with the Solana blockchain. Some recommended wallets include Phantom, Sollet, Solflare, and Ledger (for hardware wallet users).</p>
            <h3>2. Fund Your Wallet:</h3>
                <p>Purchase Solana (SOL) on an exchange like&nbsp; 
                    <a href="https://accounts.binance.com/en/login?return_to=aHR0cHM6Ly93d3cuYmluYW5jZS5jb20vZW4vbXkvc2VjdXJpdHk=" target="_blank" rel="noopener noreferrer">Binance</a>,&nbsp;  
                    <a href="https://www.coinbase.com/" target="_blank" rel="noopener noreferrer">Coinbase</a>, or&nbsp; 
                    <a href="https://www.kraken.com/" target="_blank" rel="noopener noreferrer">Kraken</a>.
                Transfer SOL to your Solana-compatible wallet.</p>
            <h3>4. Connect to <a href="https://raydium.io/swap/?inputMint=sol&outputMint=DrcedRtyc62aatceyr4UZBpGVPoFKdLqdR3hGVdGj2QV" target="_blank" rel="noopener noreferrer">Raydium</a>:</h3>
                <p>Visit <a href="https://raydium.io/swap/?inputMint=sol&outputMint=DrcedRtyc62aatceyr4UZBpGVPoFKdLqdR3hGVdGj2QV" target="_blank" rel="noopener noreferrer">Raydium</a>.
                Connect your wallet by clicking on the “Connect Wallet” button on the top right corner and selecting your wallet.</p>
            <h3>5. Navigate to the Swap Interface:</h3>
                <p>Go to the "Swap" section on <a href="https://raydium.io/swap/?inputMint=sol&outputMint=DrcedRtyc62aatceyr4UZBpGVPoFKdLqdR3hGVdGj2QV" target="_blank" rel="noopener noreferrer">Raydium</a>.</p>
            <h3>6. Select Tokens to Swap:</h3>
                <p>In the "From" field, select SOL or any other token you have.
                In the "To" field, search for and select OWLE.</p>
            <h3>7. Perform the Swap:</h3>
                <p>Enter the amount of SOL (or other token) you want to swap for OWLE.
                Review the transaction details, including the estimated amount of OWLE you will receive.
                Click "Swap" and confirm the transaction in your wallet.</p>
            <h3>8. Add OWLE to Your Wallet:</h3>

                <p>After the transaction is complete, ensure OWLE is visible in your wallet by adding the token manually if it doesn’t appear automatically.</p>

            <h3> Following these steps will help you buy OWLE tokens on Raydium using a compatible wallet. If you need further assistance, don't hesitate to reach out.</h3>
        </div>
      </div>
    </Element>
  );
};

export default Howtobuy;

import React from 'react';
import { Element } from 'react-scroll';
import './Team.css';
import aiz from '../assets/aiz.avif';
import reign from '../assets/reign.avif';
import macky from '../assets/macky.avif'

const About = () => {
  
  return (
    <Element className="team-container" id='teamPage'>
      <div className="text-container4">
        <h2>TEAM</h2>
        <div className='descrip3'>
          <p><strong>The Owlbert Einstein</strong> founders are comprised of three brothers with diverse professional
              backgrounds, one computer engineer, one Web Developer and a supply chain business
              development professional. This combination of technical expertise and business development
              acumen positions the team to effectively progress and manage the OWLE ecosystem and lead
              the below team.
          </p>
          <div className='descrip3second'>
            <p>
              • Software Development Team
            </p>
            <p>
              • Web Development Team
            </p>
            <p>
              • Business Development Team
            </p>
            <p className='indented'>
              o Marketing Team
            </p>
          </div>
        </div>
      </div>
      <div className="image-container4">
        <img src={aiz} alt="Owlbert Einstein" className="aiz-image" />
        <img src={macky} alt="Owlbert Einstein" className="macky-image" />
        <img src={reign} alt="Owlbert Einstein" className="reign-image" />
      </div>
      <div>
        
      </div>
    </Element>
  );
};

export default About;

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h1 className="logo-text"><span>OWLBERT </span>EINSTEIN</h1>
          <p>
            DISCLOSURE: $OWLE, initially a meme coin, is set to transition into a technology-driven company. This transformation will involve the establishment of a formal team and a strategic roadmap, focusing on leveraging technology to drive innovation and future value.
          </p>
          <div className="socials">
            <a href="https://t.me/OwlbertEinsteinCoin" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane"></i></a>
            <a href="https://x.com/OWLESTN" target="_blank" rel="noopener noreferrer"><i className="fab fa-x-twitter"></i></a>
            <a href="https://www.facebook.com/profile.php?id=61562869589014&mibextid=JRoKGi" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
            <a href="https://www.instagram.com/owlestn" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
            <a href="https://www.tiktok.com/@_owlberteinstein?_t=8oGJ6HkWLrh&_r=1" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></a>
            <a href="https://discord.gg/JFGMsb7JS2" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord"></i></a>
            <a href="https://www.youtube.com/@owlberteinstein" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; 2024 Owlbert | All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;

// src/components/NewSection.js
import React from 'react';
import { Element } from 'react-scroll';
import './Roadmap.css';
import roadMap from '../assets/reign.avif';
import { Helmet } from 'react-helmet-async';

const NewSection = () => {
  return (
    <Element className="roadmap-container" id='roadmapPage'>
      <Helmet>
        <meta name="description" content="Join the presale of Owlbert Einstein's $OWLE token on Raydium. Connect with us on social media and verify the official token address." />
        <meta name="keywords" content="Owlbert Einstein, OWLE token, roadmap, presale, technology-driven company" />
        <link rel="canonical" href="https://einstein-owlbert.com" />
      </Helmet>
      <div className="text-container2">
        <div className='roadmapTitle'>
          <h2>ROADMAP</h2>  
        </div>
        <div className='descrip'>
          <h3>Q3 2024 - Q4 2024:</h3>
          <p className='parag'>Meme Coin Presale and Community Building</p>
        </div>
        <div className='descrip'>
          <h3>Q2 2025:</h3>
          <p className='parag'>Launch and Integration with Major Exchanges subject to market capitalization</p> 
        </div>
        <div className='descrip'>
          <h3>Q4 2024 - Q4 2025:</h3>
          <p className='parag'>Platform Development and Community Incentives</p> 
        </div>
        <div className='descrip'>
          <h3>Q2 2026:</h3>
          <p className='parag'>Transition to Technology-driven Company</p>
        </div>
        <div className='descrip'>
          <h3>Q4 2026:</h3>
          <p className='parag'>Business Development and Global Partnerships</p>
        </div>
        <div className='reminder'>
          <h4>Reminder: The project roadmap may be subject to changes based on the complexity of the plan, potentially being expedited or delayed.</h4>  
        </div>
      </div>
      <div className="image-container2">
        <img src={roadMap} alt="Detailed roadmap of Owlbert Einstein project" className="road-map" />
      </div>
    </Element>
  );
};

export default NewSection;

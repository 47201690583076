import React from 'react';
import './Owlbert.css';
import whitePaper from '../assets/whitepaper.avif';
import download from '../assets/download.avif';

const DownloadButton = () => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = 'https://drive.google.com/uc?export=download&id=1_5nJX-azXlw7ji_rSZnKfcRjzE2g3wyI';; // Replace YOUR_FILE_ID with the actual file ID
    link.download = 'OWLE Whitepaper.pdf'; // The name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="image-containerDL">
      <img src={whitePaper} alt="Owlbert Einstein" className="owlbert-image" />
      <div className="clickable-area" onClick={handleDownload}><img src={download} alt="download" className="download" /></div>
    </div>
  );
};

export default DownloadButton;
import React from 'react';
import { Element } from 'react-scroll';
import './Tokenomics.css';
import tokenomics from '../assets/tokenomicsgraph.avif';

const Tokenomics = () => {
  return (
    <Element className="tokenomics-container" id='tokenomicsPage'>
      <div className="image-container5">
        <img src={tokenomics} alt="Owlbert Einstein" className="tokenomics-image" />
      </div>
      <div className="text-container5">
        <h2>TOKENOMICS</h2>
        <div className='descrip4'>
          <h3>TOTAL SUPPLY: 1 Billion OWLE Tokens</h3>
          <h3>Distribution:</h3>
          <div className='descrip5'>
            <p><strong>•Public Sale (60%):</strong> Ensures a broad distribution of tokens, promoting decentralization and community involvement from the outset. </p>
            <p><strong>•Team and Advisors (10%):</strong> Provides sufficient incentive for the core team and advisors to remain committed to the project's success.</p>
            <p><strong>•Marketing and Partnerships (15%):</strong> </p>
            <p className='indented2'><strong>o	Initial Release (7.5%):</strong> Supports ongoing efforts to promote the project and establish beneficial partnerships, critical for initial growth and adoption.</p>
            <p className='indented2'><strong>o	Released Over Time (7.5%):</strong> Ensures sustained marketing and partnership efforts, adapting to long-term strategic needs and opportunities. </p>
            <p><strong>•Community Incentives (15%):</strong></p>
            <p className='indented2'><strong>o	Initial Release (7.5%):</strong> Encourages active participation and rewards early contributors, helping to grow and sustain an initial vibrant community.</p>
            <p className='indented2'><strong>o	Released Over Time (7.5%):</strong> Continuously rewards and incentivizes the community, fostering long-term engagement and support for the platform.</p>
            <p><strong>•The end goal is to redistribute 100% of OWLE coins back to the community. This will be achieved gradually by adding the coins from the DEV's allocations (Team and Advisors, Marketing and Partnership, Community Incentives) to the liquidity pool and simultaneously burning the tokens.</strong></p>
          </div>
        </div>
      </div>
      
    </Element>
  );
};

export default Tokenomics;

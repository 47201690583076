import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/Logo.avif';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNavigationClick = (path) => {
    setIsOpen(false);
    setDropdownOpen(false);
    navigate(path);
  
    if (path === '/howtobuy' || path === '/newspage') {
      setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 0);
    } 
  };
  

  return (
    <div className="navbar">
      <div className="nav-container">
      <a href="/home" className="logo"> <img src={logo} alt="Logo Owlbert Einstein" /></a>
        <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
      <ul className={`nav-menu ${isOpen ? 'nav-menu-active' : ''}`}>
        <li>
          <RouterLink to="/home" onClick={() => handleNavigationClick('/home')}>
            Home
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/about" onClick={() => handleNavigationClick('/about')}>
            About
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/roadmap" onClick={() => handleNavigationClick('/roadmap')}>
            Roadmap
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/tokenomics" onClick={() => handleNavigationClick('/tokenomics')}>
            Tokenomics
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/team" onClick={() => handleNavigationClick('/team')}>
            Team
          </RouterLink>
        </li>
        <li className="dropdown">
          <span onClick={toggleDropdown}>More</span>
          <ul className={`dropdown-menu ${dropdownOpen ? 'dropdown-active' : ''}`}>
            <li><RouterLink to="/howtobuy" onClick={() => handleNavigationClick('/howtobuy')}>How to Buy</RouterLink></li>
            <li><a href="/news/latest.html">News | Updates</a></li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;

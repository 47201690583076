import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import MainPage from './pages/MainPage';
import Howtobuy from './pages/Howtobuy';
import Navbar from './components/Navbar';
import Footer from './pages/Footer';
import './App.css';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
        <Navbar />  
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/home" element={<MainPage section="home" />} />
            <Route path="/about" element={<MainPage section="about" />} />
            <Route path="/roadmap" element={<MainPage section="roadmap" />} />
            <Route path="/tokenomics" element={<MainPage section="tokenomics" />} />
            <Route path="/team" element={<MainPage section="team" />} />
            <Route path="/howtobuy" element={<Howtobuy />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;

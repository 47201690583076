import React, { useEffect, useRef } from 'react';
import { Element, scroller } from 'react-scroll';
import Owlbert from './Owlbert';
import About from './About';
import Roadmap from './Roadmap';
import Tokenomics from './Tokenomics';
import Team from './Team';
import './MainPage.css';


const MainPage = ({ section }) => {
  const sectionRefs = {
    home: useRef(null),
    about: useRef(null),
    roadmap: useRef(null),
    tokenomics: useRef(null),
    team: useRef(null),
  };

  useEffect(() => {
    if (section) {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }, [section]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          window.history.replaceState(null, '', `/${entry.target.id}`);
        }
      });
    }, observerOptions);

    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, );

  return (
    <div>
      <Element className='section-gap' name="home">
        <div id="home" ref={sectionRefs.home}>
          <Owlbert />
        </div>
      </Element>
      <Element className='section-gap' name="about">
        <div id="about" ref={sectionRefs.about}>
          <About />
        </div>
      </Element>
      <Element className='section-gap' name="roadmap">
        <div id="roadmap" ref={sectionRefs.roadmap}>
          <Roadmap />
        </div>
      </Element>
      <Element className='section-gap' name="tokenomics">
        <div id="tokenomics" ref={sectionRefs.tokenomics}>
          <Tokenomics />
        </div>
      </Element>
      <Element className='section-gap' name="team">
        <div id="team" ref={sectionRefs.team}>
          <Team />
        </div>
      </Element>
    </div>
  );
};

export default MainPage;

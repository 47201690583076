import React from 'react';
import { Element } from 'react-scroll';
import './About.css';
import about from '../assets/aiz.avif';

const About = () => {
  return (
    <Element name='about' className="about-container" id="aboutPage">
      <div className="image-container3">
        <img src={about} alt="Owlbert Einstein" className="about-image" />
      </div>
      <div className="text-container3">
        <h2>ABOUT</h2>
        <div className="descrip2">
          <p><em>Owlbert Einstein (OWLE)</em> starts as a fun, community-driven meme coin and evolves into a pioneering decentralized technology-driven company. Our mission is to engage the community from the outset with the meme coin concept and progressively develop a platform funded by the crowd of the ecosystem. Revenue generated will be redistributed back to the community, primarily going to the liquidity pool and for the development and promotion of the platform. By fostering a community-owned technology-driven company, OWLE aims to disrupt traditional models dominated by large corporations, creating a more inclusive and transparent ecosystem for its community.</p>
        </div>
      </div>
    </Element>
  );
};

export default About;

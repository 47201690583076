import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import './Owlbert.css';
import DownloadButton from './Download';
import { Helmet } from 'react-helmet-async';
import '@fortawesome/fontawesome-free/css/all.min.css';  // Import FontAwesome

const Owlbert = () => {
  const ogImage = "https://einstein-owlbert.com/assets/og-image.png";
  const twitterImage = "https://einstein-owlbert.com/assets/twitter-image.png";
  const whitepaperImage = "/static/media/whitepaper.avif";

  useEffect(() => {
    document.title = 'Home | Owlbert Einstein';
  }, []);

  return (
    <Element className="owlbert-container" id="homePage"> 
      <Helmet>
        <title>Owlbert Einstein | Evolving Beyond Meme</title>
        <meta name="description" content="Welcome to Owlbert Einstein. Explore our project, learn about our mission, and get involved in the best investment of 2024. Discover the future of cryptocurrency with OWLE, Solana, and Bitcoin insights." />
        <meta name="keywords" content="Owlbert Einstein, OWLE, cryptocurrency, best crypto of 2024, best investment in 2024, tech, innovation, Bitcoin, Solana" />
        <link rel="canonical" href="https://einstein-owlbert.com/home" />
        
        <link rel="preload" href={ogImage} as="image" />
        <link rel="preload" href={twitterImage} as="image" />
        <link rel="preload" href={whitepaperImage} as="image" />

        <meta property="og:title" content="Owlbert Einstein" />
        <meta property="og:description" content="Welcome to Owlbert Einstein. Explore our project, learn about our mission, and get involved in the best investment of 2024. Discover the future of cryptocurrency with OWLE, Solana, and Bitcoin insights." />
        <meta property="og:url" content="https://einstein-owlbert.com/home" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogImage} />
        <meta property="twitter:url" content="https://einstein-owlbert.com/home" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Owlbert Einstein" />
        <meta name="twitter:description" content="Welcome to Owlbert Einstein. Explore our project, learn about our mission, and get involved in the best investment of 2024. Discover the future of cryptocurrency with OWLE, Solana, and Bitcoin insights." />
        <meta name="twitter:image" content={twitterImage} />
      </Helmet>
      
      <div className="text-container">
        <h1>OWLBERT EINSTEIN</h1>
        <h2>$OWLE <a href="https://raydium.io/swap/?inputMint=sol&outputMint=DrcedRtyc62aatceyr4UZBpGVPoFKdLqdR3hGVdGj2QV" target="_blank" rel="noopener noreferrer">PRESALE ON RAYDIUM</a></h2>
        <h3>Join Us:</h3>
        <div className="social-icons">
          <a href="https://t.me/OwlbertEinsteinCoin" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane"></i></a>
          <a href="https://x.com/OWLESTN" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a> {/* Corrected */}
          <a href="https://www.facebook.com/profile.php?id=61562869589014&mibextid=JRoKGi" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
          <a href="https://www.instagram.com/owlestn" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          <a href="https://www.tiktok.com/@_owlberteinstein?_t=8oGJ6HkWLrh&_r=1" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></a>
          <a href="https://discord.gg/JFGMsb7JS2" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord"></i></a>
          <a href="https://www.youtube.com/@owlberteinstein" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
        </div>
        <h4>Token Address: DrcedRtyc62aatceyr4UZBpGVPoFKdLqdR3hGVdGj2QV</h4>
        <h4 className='warning'>⚠️Warning: This is the only official OWLE address. Verify before proceeding.</h4>
      </div>
      <div className="image-container">
        <DownloadButton />
      </div>
    </Element>
  );
};

export default Owlbert;
